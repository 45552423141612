import { styled } from 'Theme/stitches.config';
import type * as Stitches from '@stitches/react';
import { StyledButtonLabel } from 'DesignComponents/Atoms/Buttons/ButtonLabel/BaseButtonLabelStyle';
import { BaseProps } from './BaseButtonType';
import KexIconLoader, { IconName } from 'DesignSystem/Icons/KexIconLoader';

type StyledButtonVariants = Stitches.VariantProps<typeof Root>;

export type PrimaryButtonType = BaseProps & {
  color: StyledButtonVariants['color'];
  icon?: IconName;
  inverse?: boolean;
  id?: string;
  size?: StyledButtonVariants['size'];
};

function PrimaryButton({
  children,
  color = 'Regular',
  disabled,
  focus,
  icon,
  inverse,
  pressed,
  id,
  size,
  text,
  hug,
}: PrimaryButtonType) {
  const Icon = icon && KexIconLoader(icon);
  return (
    <Root
      color={color}
      size={size}
      hug={hug}
      {...(disabled && { disabled })}
      {...(pressed && { pressed })}
      {...(focus && { focus })}
      {...(inverse && { inverse })}
      {...(id && { id })}
    >
      {children}
      {Icon && (
        <Icon
          color={color === 'Inverse' ? 'primary' : 'inverse'}
          size={size === 'm' ? 'm' : 's'}
        />
      )}
      <StyledButtonLabel size={size}>{text}</StyledButtonLabel>
    </Root>
  );
}

const Root = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  fontSize: '$fontSize75',
  lineHeight: 'inherit',
  g: '$s75',
  cursor: 'pointer',
  alignItems: 'center',
  minHeight: '48px',
  minWidth: 'fit-content',
  py: '$s90',
  px: '$s150',
  br: 1,
  backgroundColor: '$primaryCtaBackgroundDefault',
  [`& ${StyledButtonLabel}`]: {
    color: '$onInteractiveEmphasized',
  },

  variants: {
    color: {
      Regular: {
        backgroundColor: '$primaryCtaBackgroundDefault',
        [`& ${StyledButtonLabel}`]: {
          color: '$onInteractivePrimary',
        },
        '&:hover': {
          background: '$primaryCtaBackgroundDefaultHover',
        },
      },
      Emphasized: {
        backgroundColor: '$interactiveEmphasizedBackgroundDefault',
        [`& ${StyledButtonLabel}`]: {
          color: '$onInteractiveEmphasized',
        },
        '&:hover': {
          background: '$interactiveEmphasizedBackgroundHover',
        },
      },
      Inverse: {
        backgroundColor: '$primaryCtaBackgroundInverse',
        [`& ${StyledButtonLabel}`]: {
          color: '$onInverse',
        },
        '&:hover': {
          background: '$primaryCtaBackgroundInverse',
        },
      },
    },
    size: {
      s: {
        p: '$s75',
        g: '$s50',
      },
      m: {},
    },
    disabled: {
      true: {
        backgroundColor: '$interactiveDisabled_1',
        color: '$interactiveDisabled_2',
        cursor: 'not-allowed',
        pointerEvents: 'none',
      },
    },
    inverse: {
      true: {
        flexDirection: 'row-reverse',
      },
    },
    pressed: {
      true: {},
    },

    hug: {
      height: {
        h: '100%',
      },
      width: {
        w: '100%',
      },
    },
  },
  defaultVariants: {
    color: 'Regular',
  },
  compoundVariants: [
    {
      disabled: true,
      color: 'Inverse',
      css: {
        backgroundColor: '$interactiveDisabled_1',
      },
    },
    {
      pressed: true,
      color: 'Regular',
      css: {
        background: '$primaryCtaBackgroundDefaultPressed',
        '&:hover': {
          background: '$primaryCtaBackgroundDefaultPressed',
        },
      },
    },
    {
      pressed: true,
      color: 'Emphasized',
      css: {
        background: '$interactiveEmphasizedBackgroundPressed',
        '&:hover': {
          background: '$interactiveEmphasizedBackgroundPressed',
        },
      },
    },
    {
      pressed: true,
      color: 'Inverse',
      css: {
        background: '$primaryCtaBackgroundInversePressed',
        '&:hover': {
          background: '$primaryCtaBackgroundInversePressed',
        },
      },
    },
    {
      pressed: true,
      disabled: true,
      css: {
        background: '$interactiveDisabled_1',
        '&:hover': {
          background: '$interactiveDisabled_2',
        },
      },
    },

    {
      hug: 'width',
      css: {
        justifyContent: 'center',
      },
    },
  ],
});

export default PrimaryButton;
